// @flow strict
import React from 'react';
import Header from '../Header';
import styles from './Content.module.scss';

type Props = {
  body: string,
  title: string,
};

const Content = ({ body, title }: Props) => (
  <div className={styles['content']}>
    <div
      className={styles['content__body']}
      dangerouslySetInnerHTML={{ __html: body }}
    />
  </div>
);

export default Content;
